export default({
  namespaced: true,
  state: {
    campusName: ''
  },
  getters: {
    getCampusName: state => state.campusName
  },
  mutations: {
    setCampusName: (state, data) => state.campusName = data
  },
})

