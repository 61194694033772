import Vue from 'vue'
import Main from './Message.vue'

const MessageConstructor = Vue.extend(Main)

let instance
const instances = []
let seed = 1
const Message = function (options, color, icon, typeClass) {
  options = options || {}
  if (typeof options === 'string') {
    options = {
      message: options,
      color: color,
      icon: icon,
      typeClass: typeClass
    }
  }
  const id = `message_${seed++}`
  instance = new MessageConstructor({
    data: options
  })
  instance.id = id
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.visible = true
  instance.dom = instance.vm.$el
  instance.dom.style.zIndex = 10000
  instances.push(instance)
  return instance.vm
}

Message.success = function(option) {
  Message(option, "#67c23a", "mdi-check-circle", "message--success")
}
Message.error = function(option) {
  Message(option, "#ff5252", "mdi-alert", "message--error")
}

Message.close = function (id) {
  for (let i = 0, len = instances.length; i < len; i++) {
    if (id === instances[i].id) {
      instances.splice(i, 1)
      break
    }
  }
}

Message.closeAll = function () {
  for (let i = instances.length - 1; i >= 0; i--) {
    instances[i].close()
  }
}

export default Message

Message.alert = function(option) {
  // 请更改第二个和第三个参数，第三个参数是图标，就像success的‘√’，第二个参数是图标的颜色
  Message(option, "#67c23a", "beenhere", "message--alert")
}
