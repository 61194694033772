import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSnackbar,_vm._b({class:_vm.classes,attrs:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('change', $event)}}},'v-snackbar',{
    ..._vm.$attrs,
    ..._vm.$props,
    'color': 'transparent'
  },false),[_c('base-material-alert',{staticClass:"ma-0",attrs:{"color":_vm.color,"dismissible":_vm.dismissible,"type":_vm.type,"dark":""}},[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }