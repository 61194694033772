<template>
  <!-- 自定义确认弹窗样式 -->
  <!--<v-dialog width="600px" :title="content.title" v-model="isShow">-->
  <!--  <span>{{ content.message }}</span>-->
  <!--  <div class="dialog-footer">-->
  <!--    <v-btn @click="close">-->
  <!--      取 消-->
  <!--    </v-btn>-->
  <!--    <v-btn type="primary" @click="ok">-->
  <!--      确 定-->
  <!--    </v-btn>-->
  <!--  </div>-->
  <!--</v-dialog>-->

  <v-overlay v-if="isShow" >
  <v-dialog
    v-model="isShow"
    max-width="290"
    persistent
    class="popUpMsg"
  >
    <v-card>
      <v-card-title style="font-size: 18px" tabindex="0">
        {{ content.title }}
      </v-card-title>
      <v-card-text style="font-size: 16px" tabindex="0" v-if="content.html" v-html="content.html">
      </v-card-text>
      <v-card-text style="font-size: 16px" tabindex="0" v-if="content.message">
        {{ content.message }}
      </v-card-text>
      <v-card-actions style="display: flex;justify-content: center">
        <!--<v-spacer></v-spacer>-->
        <v-btn
          v-if="!content.showTwoBtn"
          color="green"
          text
          @click="ok"
        >
          {{ content.okMsg || 'ok' }}
        </v-btn>
        <v-btn
          v-if="content.showTwoBtn"
          color="green"
          text
          @click="close"
        >
          {{ content.cancelMsg || 'cancel' }}
        </v-btn>
        <v-btn
          v-if="content.showTwoBtn"
          color="green"
          text
          @click="ok"
        >
          {{ content.okMsg || 'confirm' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-overlay>
  <!--<v-card class="dialog" v-if="isShow" :visible.sync="content.show">-->
  <!--  <v-card-title>{{ content.title }}</v-card-title>-->
  <!--  <v-card-text>-->
  <!--    {{content.message}}-->
  <!--  </v-card-text>-->
  <!--  <v-card-actions>-->
  <!--    <v-btn @click="ok">确定</v-btn>-->
  <!--    <v-btn @click="close">取消</v-btn>-->
  <!--  </v-card-actions>-->
  <!--</v-card>-->


</template>

<script>
export default {
  watch:{
    isShow(newVal,oldVal){
      console.log('isShow',newVal);
      console.log('dialog',document.getElementsByClassName());
    }
  },
  data() {
    return {
      // 弹窗内容
      isShow: true,
      content: {
        title: "",
        message: "",
        data: "",
        show: false
      },
      showOk: true
    };
  },
  methods: {
    close() {
      this.isShow = false;
    },
    ok() {
      this.isShow = false;
    }
  },

};
</script>

<style>
</style>
