<template>
  <div>
    <v-snackbar
      v-model="errorType"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="errorType = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'Alert',
    data: () => ({
      successType: false,
      infoType: false,
      warningType: false,
      errorType: false,
      timeout: 2000,
      text: ""
    }),
    methods: {
      changeError() {
        this.errorType = true;
      }
    },
    created() { // 两种接收的方式
      const that = this;
      // _this.bus.$on('error', this.errorType);
      this.bus.$on('errorType', (data) => {
        that.errorType = !!data;
      })
    }
  }
</script>

<style lang="scss">

</style>
