import axios from 'axios';

axios.interceptors.request.use(function (config) {
  // 一般在这个位置判断token是否存在
  if(localStorage.token){
    let token = localStorage.token;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response){
  // 处理响应数据
  if (response.status === 200) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
}, function (error){
  // 处理响应失败
  return Promise.reject(error);
});

export function get(url, params){
  return new Promise(
    (resolve, reject) =>{
      axios.get(url, {params: params})
        .then(res => {
          resolve(res.data);
        })
        .catch(err =>{
          reject(err.data);
        })
  });
}

export function post(url, params) {
  return new Promise(
    (resolve, reject) => {
      axios.post(url, qs.stringify(params))
    .then(res => {
        resolve(res.data);
    })
    .catch(err =>{
        reject(err.data);
    })
  });
}
