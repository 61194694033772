import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListGroup,{attrs:{"group":_vm.group,"prepend-icon":_vm.item.icon,"sub-group":_vm.subGroup,"append-icon":"mdi-menu-down","color":_vm.barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'
      ? 'white'
      : 'grey darken-1'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [(_vm.text)?_c(VListItemIcon,{staticClass:"v-list-item__icon--text",domProps:{"textContent":_vm._s(_vm.computedText)}}):(_vm.item.avatar)?_c(VListItemAvatar,{staticClass:"align-self-center",attrs:{"color":"grey"}},[(_vm.item.img != 'files/')?_c('newImg',{attrs:{"fileUrl":_vm.item.img}}):_vm._e(),(_vm.item.img=='files/' || _vm.item.img == '')?_c(VAvatar,{staticClass:"avator x-btn",attrs:{"size":"40"}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.firstName)+" "+_vm._s(_vm.lastName)+" ")])]):_vm._e()],1):(_vm.item.img)?_c(VListItemIcon,[_c(VImg,{attrs:{"src":_vm.item.img}})],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.name)}})],1)]},proxy:true}])},[_vm._l((_vm.children),function(child,i){return [(child.children)?_c('base-item-sub-group',{key:`sub-group-${i}`,attrs:{"item":child}}):_c('base-item',{key:`item-${i}`,attrs:{"item":child,"text":""}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }