<template>
  <img ref="img" :src="imgUrl"/>
</template>
<script>
import axios from "axios";

export default {
  name: "img",
  props: {
    fileUrl: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      imgUrl: ''
    }
  },
  computed: {
  },
  watch: {
    fileUrl(newVal, oldVal) {
      if(newVal != oldVal){
        this.getImg(this.fileUrl);
      }
    }
  },
  
  mounted() {
    this.getImg(this.fileUrl);
  },
  methods: {
    getImg(url){
      let _this = this;
      new Promise((reslove, reject) => {
        axios
        .get(url, {
          responseType: "blob", 
        })
        .then((r) => {
          let blob = new Blob([r.data], {type: r.headers['content-type']})
          let fileReader = new FileReader()
          fileReader.readAsDataURL(blob)
          fileReader.onload = (e) => {
            reslove(e.target.result)
          }
          fileReader.onlerror = (e) => {
            reject(new Error('file stream exception'))
          }
        })
        .catch((err) => {
          reject(err);
        });
      })
      .then(data => {
        _this.imgUrl =  data;
      })
      .catch(err => {
        console.log(err);;
      })
    }
  }
};
</script>
