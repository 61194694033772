export default({
  namespaced: true,
  state: {
    avator: ''
  },
  getters: {
    getAvator: state => state.avator
  },
  mutations: {
    setAvator: (state, data) => state.avator = data
  },
})

