<template>
  <div>
    <!--<v-alert type="success">-->
    <!--I'm a success alert.-->
    <!--</v-alert>-->
    <router-view />
    <loading></loading>
    <alert></alert>
  </div>
</template>

<script>
import axios from "axios";
import loading from "@/components/Loading.vue";
import alert from "@/components/Alert.vue";
import {mapGetters, mapMutations} from 'vuex';
import {setUser} from "@/util/dataDog";

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  components: { loading, alert },
  watch: {
    // 这个在cms跳转到webapp的时候不知道改了什么貌似不生效了，先改成created的时候调用getUserInfo
    $route(newVal, oldVal) {
      localStorage.host = window.location.host;
      console.log("route-newVal：", newVal);
      console.log("route-oldVal", oldVal);
      if (this.$route.query.token) {
        this.getUserInfo(this.$route.query.token);
        let googleAnaId = localStorage.getItem("googleAnaId");
        let client = localStorage.getItem("client");
        // let clientHasGa = client == "NatWest" || client == "Metlife";
        if (!googleAnaId) {
          this.getGaId();
        }
      } else {
        let token = localStorage.getItem("token");
        let userEmail = localStorage.getItem("userEmail");
        if (!userEmail && token) {
          this.getUserInfo(token);
        }
        let googleAnaId = localStorage.getItem("googleAnaId");
        let client = localStorage.getItem("client");
        // let clientHasGa = client == "NatWest" || client == "Metlife";
        if (token && !googleAnaId) {
          this.getGaId();
        }
      }
    }
  },
  data() {
    return {
      isRouterAlive: true //控制视图是否显示的变量
    };
  },
  methods: {
    ...mapMutations('avator', ["setAvator"]),
    getGaId() {
      console.log("zhixing getGaId");
      this.$axios({
        method: "GET",
        url: "/m/ga"
      }).then(res => {
        let gid = '';
        if(res.data.data.ga4_key){
          gid = res.data.data.ga4_key.id;
        }else{
          gid = res.data.data.id;
        }
        localStorage.setItem("googleAnaId",gid);
        // let id = res.data.data.id;
        // if (id) {
        //   localStorage.setItem("googleAnaId", id);
        // }
      });
    },
    // 关闭窗口之前执行
    async beforeunloadHandler(e) {
      await this.clearLogin(); // 退出登录接口
      window.close();
    },
    // 退出登录接口
    clearLogin() {
      localStorage.clear();
      this.$axios({
        method: "POST",
        url: "/m/logout"
      });
    },
    getMeetingData(id) {
      // let _this = this;
      let url = "";
      url = `cms/meetings/${id}`;
      axios
        .get(url)
        .then(response => {
          console.log(123124);
          console.log(response);
          localStorage.venueName = response.data.venueName;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // getUserInfo(token) {
    //   localStorage.token = token;
    //   const _this = this;
    //   let url = "m/profile/?token=" + token;
    //   axios
    //     .get(url)
    //     .then((response) => {
    //       console.log('profile', response);
    //       // localStorage.userEmail = response.data.email;
    //       localStorage.userId = response.data.userId;
    //       localStorage.userName = response.data.firstName + ' ' + response.data.lastName;
    //       localStorage.image = response.data.image;
    //       _this.$router.push({
    //         path: "/mec",
    //       }).catch(err => {
    //         console.log(err);
    //       });
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    getUserInfo(token) {
      localStorage.token = token;
      console.log("token123:", token);
      console.log("==== this.$route.query=====", this.$route.query);
      let meetingIdMap = this.$route.query.meetingIdMap;
      if (meetingIdMap) {
        localStorage.meetingIdMap = meetingIdMap;
      }
      const _this = this;
      if (!token) {
        return;
      }
      let url = "m/profile/";
      axios
        .get(url)
        .then(response => {
          console.log("profile", response);
          localStorage.userEmail = response.data.email;
          localStorage.userId = response.data.userId;
          localStorage.userName = response.data.firstName + " " + response.data.lastName;
          localStorage.image = response.data.image;
          _this.setAvator(response.data.image);
          // _this.$store.state.avator = localStorage.image;
          console.log(_this.$store.state.confidanielDomain, "0000000000000");
          _this.getUserAttendees(localStorage.userId);
          // _this.$router.push({path: "/mec"}).catch(err => {
          //   console.log(err);
          // });
          setUser(response.data);
        })
        .catch(err => {
          console.log(err);
        });
    },

    getUserAttendees(userId) {
      console.log("sunsunsuns", userId);
      const _this = this;
      let url = "m/attendees/?";
      let meetingIdMap = this.$route.query.meetingIdMap;
      if (meetingIdMap) {
        localStorage.meetingIdMap = meetingIdMap;
        url += "meetingId=" + localStorage.meetingIdMap + "&";
      }
      if (userId) {
        url += "attendeeId=" + userId;
      }

      axios
        .get(url)
        .then(response => {
          // console.log('sunsunsunsun', response);
          // console.log('sunsun','1231222423423');
          localStorage.setItem("userConfidanielDomain", response.data.confidanielDomain);
          _this.$store.commit("setConfidanielDomain", response.data.confidanielDomain);
          console.log(
            this.$store.state.confidanielDomain,
            localStorage.getItem("userConfidanielDomain"),
            "sunsuns"
          );
          // localStorage.setItem('userConfidanielDomain') = response.data.confidanielDomain;
          // localStorage.userId = response.data.userId;
          // localStorage.userName = response.data.firstName + ' ' + response.data.lastName;
          // localStorage.image = response.data.image;
          // _this.$router.push({path: "/mec"}).catch(err => {
          //   console.log(err);
          // });
        })
        .catch(err => {
          console.log(err);
        });
    },
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true; //再打开
      });
    }
  },
  created() {
    // watch不执行那个方法了，改成created先获取用户信息2022-2-8
    const root = document.documentElement;
    root.style.setProperty('--theme-color', '#ffc200');
    let token = localStorage.getItem("token");
    console.log("App-token:", token);
    if (token) {
      this.getUserInfo(token);
      this.getClientData().then(res => {
        // console.log("====resresres =====",res)
        let client = res.data.Client;
        localStorage.setItem("client", client);
        if (client == "NatWest" || client == "Metlife") {
          this.getGaId(); //获取GaId
        }
      });
    }
  },
  mounted() {
    // window.addEventListener('beforeunload', (e) => {
    //   localStorage.removeItem('cmsAdmin');
    // });
    /**
     *判断浏览器窗口是刷新或者关闭操作
     * 无论任何浏览器不管是刷新或者是关闭都会执行下面两个方法 onbiforeunload  onunload
     * 根据时间差来实现
     * 在点击刷新或者关闭时开始计时记录下这一刻的时间戳
     * 因为刷新和关闭 在执行onunload方法时的时间不一样，一般情况下经过测试
     * 关闭时时间差不大于3毫秒
     * 刷新时即使只有一个简单的helloworld页面都不少于10毫秒
     * 而一般网站网页内容更多，时间差达到了100多毫秒
     *
     *
     * 下面方法的缺点是不管你开了多少窗口，都会在关闭一个窗口时清空所有localStorage缓存
     * 造成所有页面都需要重新登录，其实这也是针对项目而言的，目前本项目就不认为这是缺点
     * 而能更好的保护用户信息
     **/
    // let beginTime = 0;//开始时间
    // let differTime = 0;//时间差
    //
    // window.addEventListener('unload', (e) => {
    //   differTime = new Date().getTime() - beginTime;
    //   if(differTime <= 5){
    //     this.beforeunloadHandler();
    //   }
    // });
    // window.addEventListener('beforeunload', (e) => {
    //   beginTime = new Date().getTime();
    // });
  },
  destroyed() {
    // 关闭浏览器执行退出接口
    // window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  }
};
</script>

<style lang="scss">
@import "./assets/css/app";
</style>
