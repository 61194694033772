import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"v-card--wizard",attrs:{"elevation":"12","max-width":"700"}},[_c(VCardTitle,{staticClass:"justify-center display-2 font-weight-light pt-5"},[_vm._v(" Build your profile ")]),_c('div',{staticClass:"text-center display-1 grey--text font-weight-light mb-6"},[_vm._v(" This information will let us know more about you. ")]),_c(VTabs,{ref:"tabs",attrs:{"background-color":"green lighten-5","color":"white","grow":"","slider-size":"50"},model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_c(VTabsSlider,{staticClass:"mt-1",attrs:{"color":"success"}}),_vm._l((_vm.items),function(item,i){return _c(VTab,{key:i,attrs:{"ripple":false,"disabled":!_vm.availableSteps.includes(i)}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('div',{staticClass:"my-6"}),_c(VCardText,[_c(VTabsItems,{model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},[_vm._t("default")],2)],1),_c(VCardActions,{staticClass:"pb-4 pa-4"},[_c(VBtn,{staticClass:"white--text",attrs:{"disabled":_vm.internalValue === 0,"color":"grey darken-2","min-width":"125"},on:{"click":function($event){return _vm.$emit('click:prev')}}},[_vm._v(" Previous ")]),_c(VSpacer),_c(VBtn,{attrs:{"disabled":!_vm.availableSteps.includes(_vm.internalValue + 1),"color":"success","min-width":"100"},on:{"click":function($event){return _vm.$emit('click:next')}}},[_vm._v(" "+_vm._s(_vm.internalValue === _vm.items.length - 1 ? 'Finish' : 'Next')+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }