export default({
  namespaced: true,
  state: {
    meetingId: ''
  },
  getters: {
    getMeetingId: state => state.meetingId
  },
  mutations: {
    setMeetingId: (state, data) => state.meetingId = data
  },
})

