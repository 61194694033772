import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item.title !== 'Logout')?_c(VListItem,{attrs:{"href":_vm.href,"rel":_vm.href && _vm.href !== '#' ? 'noopener' : undefined,"target":_vm.href && _vm.href !== '#' ? '_blank' : '_self',"to":_vm.item.to,"active-class":"navBarActive"},on:{"click":function($event){return _vm.navClick(_vm.item.title)}}},[(_vm.text)?_c(VListItemIcon,{staticClass:"v-list-item__icon--text",domProps:{"textContent":_vm._s(_vm.computedText)}}):(_vm.item.icon)?_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.item.icon)}})],1):(_vm.item.img)?_c(VListItemIcon,[_c(VImg,{attrs:{"src":_vm.item.img}})],1):_vm._e(),(_vm.item.title || _vm.item.subtitle)?_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.subtitle)}})],1):_vm._e()],1):_vm._e(),(_vm.item.title === 'Logout')?_c(VListItem,{on:{"click":function($event){return _vm.go()}}},[(_vm.text)?_c(VListItemIcon,{staticClass:"v-list-item__icon--text",domProps:{"textContent":_vm._s(_vm.computedText)}}):(_vm.item.icon)?_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(_vm.item.icon)}})],1):(_vm.item.img)?_c(VListItemIcon,[_c(VImg,{attrs:{"src":_vm.item.img}})],1):_vm._e(),(_vm.item.title || _vm.item.subtitle)?_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.item.title)}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.item.subtitle)}})],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }