import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isShow)?_c(VOverlay,[_c(VDialog,{staticClass:"popUpMsg",attrs:{"max-width":"290","persistent":""},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c(VCard,[_c(VCardTitle,{staticStyle:{"font-size":"18px"},attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(_vm.content.html)?_c(VCardText,{staticStyle:{"font-size":"16px"},attrs:{"tabindex":"0"},domProps:{"innerHTML":_vm._s(_vm.content.html)}}):_vm._e(),(_vm.content.message)?_c(VCardText,{staticStyle:{"font-size":"16px"},attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.content.message)+" ")]):_vm._e(),_c(VCardActions,{staticStyle:{"display":"flex","justify-content":"center"}},[(!_vm.content.showTwoBtn)?_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.ok}},[_vm._v(" "+_vm._s(_vm.content.okMsg || 'ok')+" ")]):_vm._e(),(_vm.content.showTwoBtn)?_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.content.cancelMsg || 'cancel')+" ")]):_vm._e(),(_vm.content.showTwoBtn)?_c(VBtn,{attrs:{"color":"green","text":""},on:{"click":_vm.ok}},[_vm._v(" "+_vm._s(_vm.content.okMsg || 'confirm')+" ")]):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }