import {datadogRum} from "@datadog/browser-rum";

const setUser = (user) => {
  if(user) {
    datadogRum.setUser({
      id: user.userId,
      email: user.email,
      name: user.firstName + " " + user.lastName,
    });
  }
}

export {setUser};