import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"text-center v-card--testimony"},[_c('div',{staticClass:"pt-6"},[_c(VIcon,{attrs:{"color":"black","x-large":""}},[_vm._v(" mdi-format-quote-close ")])],1),_c(VCardText,{staticClass:"display-1 font-weight-light font-italic mb-3",domProps:{"textContent":_vm._s(_vm.blurb)}}),_c('div',{staticClass:"display-2 font-weight-light mb-2",domProps:{"textContent":_vm._s(_vm.author)}}),_c('div',{staticClass:"body-2 text-uppercase grey--text",domProps:{"textContent":_vm._s(_vm.handle)}}),_c(VAvatar,{attrs:{"color":"grey","size":"100"}},[_c(VImg,{attrs:{"alt":`${_vm.author} Testimonial`,"src":_vm.avatar}})],1),_c('div')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }